import React, {ReactNode} from 'react';
import styles from './Navbar.module.scss';

export interface NavbarProps {
  leftComponent?: ReactNode;
  centerComponent?: ReactNode;
  rightComponent?: ReactNode;
}

function Navbar({
  leftComponent,
  centerComponent,
  rightComponent,
}: NavbarProps): JSX.Element {
  return (
    <header className={styles.navbar}>
      <div className={styles.slot}>{leftComponent}</div>
      <div className={`${styles.slot} ${styles.center}`}>{centerComponent}</div>
      <div className={`${styles.slot} ${styles.end}`}>{rightComponent}</div>
    </header>
  );
}

export default Navbar;
