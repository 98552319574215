/* eslint-disable react/function-component-definition */
import React, {useState} from 'react';
import SideBar, {MenuItem, Theme} from 'components/infra/SideBar';
import Navbar from 'components/infra/Navbar';
import Image from 'components/infra/Image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from '../../../styles/images/letrus-logo.svg';
import styles from './PageWrapper.module.scss';
import {UserRole} from '../../../constants';

export interface PageWrapperProps {
  topMenu: MenuItem[];
  bottomMenu?: MenuItem[];
  theme: Theme;
  centerComponent?: React.ReactNode;
  rightComponent: React.ReactNode;
  children: React.ReactNode;
  userRole?: UserRole;
  isMenuButtonDisabled?: boolean;
}

function PageWrapper({
  topMenu,
  theme,
  rightComponent,
  bottomMenu,
  children,
  centerComponent,
  isMenuButtonDisabled,
  userRole = 'anonymous',
}: PageWrapperProps): React.ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navbarLeftComponent = (
    <button
      disabled={isMenuButtonDisabled}
      className={`${styles.menuButton} ${
        isMenuButtonDisabled ? styles.disabledMenuButton : ''
      }`}
      onClick={() => setIsSidebarOpen(isOpen => !isOpen)}
      data-testid="menuButton"
    >
      <FontAwesomeIcon icon="bars" size="lg" color="#3C405F" />
    </button>
  );

  return (
    <div className={styles.wrapper}>
      <Navbar
        leftComponent={navbarLeftComponent}
        centerComponent={
          centerComponent ?? <Image alt="Logo Letrus" imageSrc={Logo} />
        }
        rightComponent={rightComponent}
      />

      <div className={styles.body}>
        <SideBar
          topMenu={topMenu}
          bottomMenu={bottomMenu}
          closed={!isSidebarOpen}
          theme={theme}
          userRole={userRole}
        />

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

export default PageWrapper;
